import React from "react";
import "./Button.css";
function Button(props) {
  let button = (
    <button
      className={props.styles}
      onClick={props.clickHandler}
      disabled={props.disableProps}
      id={props.id}
    >
      {props.title}
    </button>
  );
  if (props.disableProps) {
    button = (
      <a data-tip data-for="disabled">
        <button
          className={props.styles}
          onClick={props.clickHandler}
          disabled={props.disableProps}
          id={props.id}
        >
          {props.title}
        </button>
      </a>
    );
  }
  return button;
}

export default Button;
