const axios = require("axios");

const loginAPI = async (email, password) => {
  const data = JSON.stringify({ email: email, password: password });

  const config = {
    method: "post",
    url: "https://plethorait-bitly-db.herokuapp.com/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const result = await axios(config);
    return result;
  } catch (error) {
    console.log(error.message);
    throw new Error(error);
  }
};

export default loginAPI;
