/**
 * This file handles the chart formed, Making use of ChartJs library.
 */

import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import bitlyAPI from "../../Utility/axios";
import Spinner from "../../Components/UI/Spinner/Spinner";
import "./BarChart.css";
class BarChart extends Component {
  state = {
    data: null,
    totalClicks: 0,
    dateID: "",
    loading: false,
  };

  //using momentjs to format the date send through API
  newDate(UTC) {
    return moment(UTC)._d;
  }

  //setting up dates for respective dateID
  datesHandler(dateID) {
    let date1, date2;
    if (dateID === "feb") {
      date1 = "2021-01-31T00:00:00.000Z";
      date2 = "2021-03-01T00:00:00.000Z";
    } else if (dateID === "mar") {
      date1 = "2021-02-28T00:00:00.000Z";
      date2 = "2021-04-01T00:00:00.000Z";
    } else if (dateID === "apr") {
      date1 = "2021-03-31T00:00:00.000Z";
      date2 = "2021-05-01T00:00:00.000Z";
    } else {
      date1 = "2021-01-31T00:00:00.000Z";
      date2 = "2021-05-01T00:00:00.000Z";
    }
    return { date1, date2 };
  }

  //setting up chart dataset by calling the API
  chartCreation(result) {
    this.setState({ ...this.state, loading: true });
    const linkClicks = result.data;
    let dataset = [];
    let label = [];
    linkClicks.forEach((click, index) => {
      dataset[index] = click.clicks;
      label[index] = this.newDate(click.date);
    });
    this.setState({
      totalClicks: dataset.reduce((a, b) => a + b, 0), //sum all the clicks
      data: {
        labels: label,
        datasets: [
          {
            label: "Total Clicks",
            data: dataset,
            backgroundColor: "rgb(135, 206, 235)",
            borderWidth: 1,
          },
        ],
      },
      dateID: this.props.dateID,
    });
  }

  async componentDidUpdate() {
    if (this.state.dateID !== this.props.dateID) {
      //clause to prevent infinite loop after updating state
      const dates = this.datesHandler(this.props.dateID);
      try {
        const result = await bitlyAPI(dates); //fetch the bitly data for number of clicks
        if (result) {
          this.chartCreation(result);
        }
      } catch (error) {
        alert(error.message);
      }
    }
  }

  //mount data of all months when started
  async componentDidMount() {
    const dates = this.datesHandler(this.props.dateID);
    try {
      const result = await bitlyAPI(dates); //fetch the bitly data for number of clicks
      if (result) {
        this.chartCreation(result);
      }
    } catch (error) {
      alert(error.message);
    }
  }
  render() {
    let barChart;

    if (this.state.loading) {
      barChart = <Spinner></Spinner>;
    }
    if (this.state.data?.datasets?.[0]?.data?.length === 0) {
      //If there is no data to show
      barChart = (
        <div className="no-data">
          <i className="fas fa-2x fa-bullhorn"></i>
          <p>Your campaign has not started yet.</p>
        </div>
      );
    } else if (this.state.data) {
      barChart = (
        <div>
          <p>
            Total Clicks: <span> {this.state.totalClicks}</span>
          </p>
          <div className="chart">
            <Bar
              data={this.state.data}
              width={600}
              height={400}
              options={{
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      type: "time",
                      time: {
                        unit: "day",
                        round: "day",
                        displayFormats: {
                          day: "MMM D",
                        },
                      },
                      offset: true,
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    title: function (tooltipItem) {
                      return tooltipItem[0].label.slice(0, 12);
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      );
    }
    return <div>{barChart}</div>;
  }
}
export default BarChart;
