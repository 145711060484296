import ReactTooltip from "react-tooltip";
import React from "react";
import "./Tooltip.css";
function Tooltip(props) {
  let message = (
    <span>
      This data will be available at <br />
      the start of the month
    </span>
  );
  if (props.message) {
    message = <span>Your campaign hasn't started yet</span>;
  } else {
    return null;
  }
  return (
    <ReactTooltip
      className="tooltip"
      id="disabled"
      place="bottom"
      type="warning"
      effect="solid"
    >
      {message}
    </ReactTooltip>
  );
}

export default Tooltip;
