import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import BarChart from "../BarChart/BarChart";
import Login from "../Login/Login";
import Logo from "../../Components/Logo/Logo";
import Button from "../../Components/UI/Button/Button";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import "./Main.css";

class Main extends Component {
  state = {
    loggedIn: false,
    dateID: "all",
  };

  loginController = () => {
    this.setState({ loggedIn: true });
  };

  //function to check which buttons are to be disabled
  updateDisableButton = () => {
    let date = new Date().toISOString();
    let disabledProp = [true, true, true, true];
    if (date > "2021-03-31T00:00:00.000Z") {
      disabledProp[0] = disabledProp[1] = disabledProp[2] = disabledProp[3] = false;
    } else if (date > "2021-02-28T00:00:00.000Z") {
      disabledProp[0] = disabledProp[1] = disabledProp[3] = false;
    } else if (date > "2021-01-31T00:00:00.000Z") {
      disabledProp[0] = disabledProp[3] = false;
    }
    return disabledProp;
  };

  componentDidMount() {
    this.updateDisableButton();
    const loggedInUser = localStorage.getItem("userEmail");
    if (loggedInUser) {
      this.setState({ loggedIn: true });
    }
  }
  handleLogout = () => {
    localStorage.clear();
    this.setState({ loggedIn: false });
  };

  //update graph by changing dates
  dateChangeHandler = (event) => {
    this.setState({
      ...this.state,
      dateID: event.target.id,
    });
  };
  render() {
    const disabled = this.updateDisableButton();
    let mainComponent = ( //if the user is not logged in
      <Switch>
        <Route
          path="/dashboardLogin"
          render={() => <Login controller={this.loginController}></Login>}
        />
        <Redirect to="/dashboardLogin"></Redirect>
      </Switch>
    );
    if (this.state.loggedIn) {
      //if the user is logged in display charts
      mainComponent = (
        <section id="main">
          <div className="container">
            <header>
              <Logo />
              <Button
                styles="btn"
                clickHandler={this.handleLogout}
                title="Log Out"
              />
            </header>
            <div className="btn-group">
              <Button
                styles={
                  this.state.dateID === "all" ? "btn s-btn active" : "btn s-btn"
                }
                clickHandler={this.dateChangeHandler}
                title="All records"
                id="all"
                disableProps={disabled[3]}
              />
              <Button
                styles={
                  this.state.dateID === "feb" ? "btn s-btn active" : "btn s-btn"
                }
                clickHandler={this.dateChangeHandler}
                title="February"
                id="feb"
                disableProps={disabled[0]}
              />
              <Button
                styles={
                  this.state.dateID === "mar" ? "btn s-btn active" : "btn s-btn"
                }
                clickHandler={this.dateChangeHandler}
                title="March"
                id="mar"
                disableProps={disabled[1]}
              />
              <Button
                styles={
                  this.state.dateID === "apr" ? "btn s-btn active" : "btn s-btn"
                }
                clickHandler={this.dateChangeHandler}
                title="April"
                id="apr"
                disableProps={disabled[2]}
              />
              <Tooltip message={disabled[3] ? "message" : null} />
            </div>
            <Switch>
              <Route
                path="/dashboard"
                exact
                render={() => <BarChart dateID={this.state.dateID}></BarChart>}
              ></Route>
              <Redirect to="/dashboard"></Redirect>
            </Switch>
          </div>
        </section>
      );
    }

    return mainComponent;
  }
}

export default Main;
