//Using axios to perform API call to DB
const axios = require("axios");
const { REACT_APP_DB } = process.env;

const bitlyAPI = async (dates) => {
  let params;
  if (dates) {
    params = dates;
  }
  const config = {
    method: "get",
    url: REACT_APP_DB + "/clicks",
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  };
  try {
    const result = await axios(config);
    return result;
  } catch (error) {
    throw new Error(error);
  }
};
export default bitlyAPI;
