import React, { Component } from "react";
import "./Login.css";
import loginAPI from "../../Utility/loginAPI";
import Logo from "../../Components/Logo/Logo";
class Login extends Component {
  state = {
    emailId: "",
    password: "",
    isPasswordShown: false,
    error: null,
  };
  submitHandler = async (event) => {
    event.preventDefault();
    try {
      const result = await loginAPI(this.state.emailId, this.state.password);
      localStorage.setItem("userEmail", result.data.user.email); //updating local storage when logged in
      this.props.controller();
    } catch (error) {
      alert(error.message);
    }
  };
  ChangeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ ...this.state, isPasswordShown: !isPasswordShown });
  };

  render() {
    const { isPasswordShown } = this.state;
    return (
      <div className="loginForm">
        <form onSubmit={this.submitHandler}>
          <Logo />
          <h1>Social Box Log In</h1>
          <div className="form-group">
            <label>Email ID</label>
            <input
              type="text"
              name="emailId"
              onChange={this.ChangeHandler}
            ></input>
          </div>
          <div className="form-group">
            <label>Password</label>
            <div className="input-password">
              <input
                type={isPasswordShown ? "text" : "password"}
                name="password"
                id="password"
                onChange={this.ChangeHandler}
              ></input>
              <span
                onClick={this.togglePasswordVisiblity}
                className="fas fa-eye toggle-password"
              ></span>
            </div>
          </div>
          <button type="submit" className="Loginbtn Loginbtn-block">
            Log In
          </button>
        </form>
      </div>
    );
  }
}
export default Login;
