import React from "react";
import Lottie from "react-lottie-player";
import lottieJson from "../../../Assets/loader.json";
const Spinner = () => {
  return (
    <div className="loader">
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 100, height: 100 }}
        speed="1"
      />
    </div>
  );
};

export default Spinner;
