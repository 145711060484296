import React from "react";
import LogoImage from "../../Assets/images/broLogo.svg";
import "./Logo.css";
const Logo = () => {
  return (
    <div className="logo">
      <img src={LogoImage} alt="BRO Logo"></img>
    </div>
  );
};
export default Logo;
